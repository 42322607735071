const games = [
  {
    name: {
      en: "You Don't Know Jack - 2015",
      ru: "А голову ты не забыл? - 2015",
    },
    pack: "The Jackbox Party Pack",
    image: {
      en: "ydkj_2015.jpg",
    },
    minPlayers: 1,
    maxPlayers: 4,
    url: "https://jackboxgames.com/ydkj-2015",
    description: {
      en: "The comedy trivia sensation with hundreds of all-new questions.",
      ru: "Старая добрая комическая викторинка с весёлыми рубриками. И звуками пердежа.",
      ua: "Комедійна вікторина із сотнями нових питань",
    },
    packUrl: "https://www.jackboxgames.com/party-pack/",
    duration: "20 Minutes",
    familyFriendlySetting: false,
    manualCensoring: false,
    extendedTimers: false,
    translations: [],
  },
  {
    name: {
      en: "Lie Swatter",
      ru: "Мухи-врухи",
    },
    pack: "The Jackbox Party Pack",
    image: {
      en: "lie_swatter.jpg",
    },
    minPlayers: 1,
    maxPlayers: 100,
    url: "https://jackboxgames.com/lie-swatter",
    description: {
      en: "The wacky-fact-filled Lie Swatter.",
      ru: "Игра типа «правда-ложь» для почти бесконечного количества игроков. Места хватит всем!",
      ua: "Гра, в якій потрібно зрозуміти, де правда, а де брехня.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack/",
    duration: "20 Minutes",
    familyFriendlySetting: false,
    manualCensoring: false,
    extendedTimers: false,
    translations: ["ru"],
  },
  {
    name: {
      en: "Fibbage XL",
      ru: "Бредовуха XL",
    },
    pack: "The Jackbox Party Pack",
    image: {
      en: "fibbage_xl.jpg",
    },
    minPlayers: 2,
    maxPlayers: 8,
    url: "https://jackboxgames.com/fibbage-xl",
    description: {
      en: "The hilarious bluffing game with 50% more questions added to the original hit.",
      ru: "Игра, в которой надо придумывать ложные варианты ответа и одновременно искать правдивые. В версии XL на 50% больше вопросов, чем в оригинальной версии!",
      ua: "Весела гра на блеф, у якій додано на 50% більше запитань, ніж в оригіналі.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack/",
    duration: "15-20 Minutes",
    familyFriendlySetting: true,
    manualCensoring: false,
    extendedTimers: false,
    translations: [],
  },
  {
    name: {
      en: "Word Spud",
      ru: "Словоблуд",
    },
    pack: "The Jackbox Party Pack",
    image: {
      en: "word_spud.jpg",
    },
    minPlayers: 2,
    maxPlayers: 8,
    url: "https://jackboxgames.com/word-spud",
    description: {
      en: "The racy-as-you-want-to-be fill-in-the-blank word game.",
      ru: "Игра в которой тебе надо дополнить текст так, как ты захочешь, лишь бы это залайкали остальные игроки.",
      ua: "Гра, у якій потрібно заповнити пропуски будь-яким чином, аби було смішно.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack/",
    duration: "Unlimited",
    familyFriendlySetting: false,
    manualCensoring: false,
    extendedTimers: false,
    translations: ["ru"],
  },
  {
    name: {
      en: "Drawful",
      ru: "Рисовач",
      ua: "Малювач",
    },
    pack: "The Jackbox Party Pack",
    image: {
      en: "drawful.jpg",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://jackboxgames.com/drawful",
    description: {
      en: "The bizarre drawing game where you draw right there on your phone or tablet.",
      ru: "Рисуй понятные рисунки, а твои друзья постараются отгадать их. Но они тоже в деле и будут предлагать свои варианты, дабы сбить всех с толку! Кто из вас рисует красивее всех, а кто понятнее всех?",
      ua: "Гра на малювання, де твої друзі мають здогадатися, що зображено на твоєму малюнку. Але кожен з них запропонує свій варіант, щоб заплутати інших.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack/",
    duration: "15-20 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Bomb Corp.",
      ru: "БомбоКорпорация",
    },
    pack: "The Jackbox Party Pack 2",
    image: {
      en: "bomb_corp.jpg",
    },
    minPlayers: 1,
    maxPlayers: 4,
    url: "https://jackboxgames.com/bomb-corp",
    description: {
      en: "The bomb-defusing nailbiter of a party game! As interns at Bomb Corp., you must defuse random bombs in the office in order to keep your jobs. You’ll probably die, but it’ll be good work experience!",
      ru: "Вы — стажёры БомбоКорпорации, которые должны обезвреживать бомбы, чтобы поддерживать слаженную работу в офисе. Скорее всего, вы все подорвётесь, зато какое веселье!",
      ua: "Ви граєте за стажерів у Bomb Corp і маєте знешкодити різні бомби в офісі, щоб зберегти роботу. Вибухова вечірня гра!",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-two/",
    duration: "15-20 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: [],
  },
  {
    name: {
      en: "Fibbage 2",
      ru: "Бредовуха 2",
    },
    pack: "The Jackbox Party Pack 2",
    image: {
      en: "fibbage_2.jpg",
    },
    minPlayers: 2,
    maxPlayers: 8,
    url: "https://jackboxgames.com/fibbage-two",
    description: {
      en: "The runaway hit bluffing game with over 500 brand-new questions, more than 2x the original! Plus new features, like the deFIBrillator!",
      ru: "Вторая часть игры, в которой надо придумывать ложные варианты ответа и одновременно искать правдивые. Теперь вопросов более 500, что более чем в 2 раза больше, чем в первой части. Плюс новая фишка — деФИБриллятор! ",
      ua: "Нестримна друга частина гри на блеф, у якій понад 500 абсолютно нових питаннь!",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-two/",
    duration: "15-20 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: [],
  },
  {
    name: {
      en: "Bidiots",
      ru: "Дуракцион",
    },
    pack: "The Jackbox Party Pack 2",
    image: {
      en: "bidiots.jpg",
      ru: "bidiots.png",
    },
    minPlayers: 3,
    maxPlayers: 6,
    url: "https://jackboxgames.com/bidiots",
    description: {
      en: "The absurd art auction game where you draw right there on your phone or tablet. Outbid your opponents for weird art pieces – drawn by players themselves – and win this strangely competitive auction game! Don’t be a bidiot!",
      ru: "Абсурдная игра-аукцион картинной галереи. Стоимость картин назначают секретные эксперты, а сами картины пишут… наши игроки! Купи по дешёвке самые ценные картины, но не дай другим переиграть тебя!",
      ua: "Абсурдна гра на аукціоні мистецтва. Переплюньте своїх опонентів за дивні твори мистецтва, намальовані самими гравцями, і перемагайте в цій дивній змагальній грі на аукціоні!",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-two/",
    duration: "15-20 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Earwax",
      ru: "Звукварь",
    },
    pack: "The Jackbox Party Pack 2",
    image: {
      en: "earwax.jpg",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://jackboxgames.com/earwax",
    description: {
      en: "The hear-larious sound-effects game that will leave you up to your ears in laughter! Cow moo? Huge explosion? Or tiny fart? Which to choose?",
      ru: "Звуки «МУ»? Большой взрыв? Или яростный пердёж? Выбери самые весёлые звуки и одержи победу.",
      ua: "Смішна гра зі звуковими ефектами, яка змусить вас сміятися до вух! Мукає корова? Чи величезний вибух? Або може маленький пук? Що обрати?",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-two/",
    duration: "15-20 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Quiplash XL",
      ru: "СмеXLыст",
    },
    pack: "The Jackbox Party Pack 2",
    image: {
      en: "quiplash_xl.jpg",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://jackboxgames.com/quiplash-xl",
    description: {
      en: "The say-anything, gut-busting Quiplash XL, which includes everything in Quiplash, Quip Pack 1, AND over 100 brand-new prompts!",
      ru: "Шути, что хочешь, и поддерживай хорошие варианты. На 100 весёлых заданий больше, чем в оригинальной (не-XL и не 2) версии!",
      ua: "Вражаючий Quiplash XL, який включає все із Quiplash, Quip Pack 1 і понад 100 абсолютно нових запитань!",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-two/",
    duration: "15-20 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Trivia Murder Party",
      ru: "Смертельная вечеринка",
      ua: "Смертельна вечірка",
    },
    pack: "The Jackbox Party Pack 3",
    image: {
      en: "trivia_murder_party.jpg",
    },
    minPlayers: 1,
    maxPlayers: 8,
    url: "https://jackboxgames.com/trivia-murder-party",
    description: {
      en: "A deadly quiz show where you match wits with a trivia-obsessed killer.",
      ru: "Ужасно страшная викторина, где ты встретишься со свихнувшимся серийным убийцей, который любит проверять интеллект!",
      ua: "Лячна смертельна вікторина, де ви відповідаєте на питання одержимого вбивці.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-three/",
    duration: "15-20 Minutes",
    familyFriendlySetting: false,
    manualCensoring: false,
    extendedTimers: true,
    translations: [],
  },
  {
    name: {
      en: "Guesspionage",
      ru: "Нашшпионаж",
    },
    pack: "The Jackbox Party Pack 3",
    image: {
      en: "guesspionage.jpg",
      ru: "guesspionage.png",
    },
    minPlayers: 2,
    maxPlayers: 8,
    url: "https://jackboxgames.com/guesspionage",
    description: {
      en: "The brain-battering data-mining guessing game.",
      ru: "Игра, в которой тебе предстоит угадывать результаты анализа данных о других людях.",
      ua: "Гра у відгадуванні результатів аналізу даних о людях.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-three/",
    duration: "10-15 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Fakin' It",
      ru: "Обмани меня!",
    },
    pack: "The Jackbox Party Pack 3",
    image: {
      en: "fakin_it.jpg",
      ru: "fakin_it.png",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://jackboxgames.com/fakin-it",
    description: {
      en: "One of your friends has something to hide in this sneaky game for tricksters.",
      ru: "У твоего друга будет секрет, который тебе и надо раскрыть в этой игре, созданной для удачливых обманщиков.",
      ua: "Дехто із твоїх друзів щось приховує, а ви маєте його викрити. Гра просто створена для талановитих шахраїв!",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-three/",
    duration: "15-20 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: false,
    translations: ["ru"],
  },
  {
    name: {
      en: "Quiplash 2",
      ru: "Смехлыст 2",
    },
    pack: "The Jackbox Party Pack 3",
    image: {
      en: "quiplash_2.jpg",
      ru: "quiplash_2.png",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://jackboxgames.com/quiplash-two",
    description: {
      en: "The say-anything sequel. Play all new questions or make your own!",
      ru: "Сиквел, в котором ты можешь говорить всё, что хочешь. Играй с нашими новыми заданиями или придумай свои!",
      ua: "Сіквел, в якому можеш казати будь-що. Грайте із новими запитаннями або створюйте власні!",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-three/",
    duration: "15 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Tee K.O.",
      ru: "Футбол К.О.",
    },
    pack: "The Jackbox Party Pack 3",
    image: {
      en: "tee_ko.jpg",
      ru: "tee_ko.png",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://jackboxgames.com/tee-ko",
    description: {
      en: "The t-shirt slugfest where you battle your custom t-shirts to the death!",
      ru: "Фестиваль одежды, где придуманные игроками футболки сражаются не на жизнь, а на смерть.",
      ua: "Фестиваль футболок, де ви змагаєтеся своїми нестандартними малюнками.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-three/",
    duration: "30 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Fibbage 3",
      ru: "Бредовуха 3",
    },
    pack: "The Jackbox Party Pack 4",
    image: {
      en: "fibbage_3.png",
    },
    minPlayers: 2,
    maxPlayers: 8,
    url: "https://jackboxgames.com/fibbage-three",
    description: {
      en: "The blanking fun sequel with all-new question types and the game mode Fibbage: Enough About You where you guess the weird facts about your friends.",
      ru: "Третья часть игры на заполнение пропусков в интересных фактах, теперь с новыми типами вопросов и игровым режимом «Бредовуха: Мы всё про вас знаем».",
      ua: "Веселе продовження безглуздої гри з абсолютно новими типами запитань та новим ігровим режимом «Ми все про вас знаємо», де ви вгадуєте дивні факти про своїх друзів.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-four/",
    duration: "15-20 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Monster Seeking Monster",
      ru: "Монстр ищет монстра",
      ua: "Монстр шукає монстра",
    },
    pack: "The Jackbox Party Pack 4",
    image: {
      en: "monster_seeking_monster.jpg",
      ru: "monster_seeking_monster.png",
    },
    minPlayers: 3,
    maxPlayers: 7,
    url: "https://jackboxgames.com/monster-seeking-monster",
    description: {
      en: "The spooky date-a-thon where you message and date fellow monsters with special powers.",
      ru: "Марафон ужасных свиданий, где тебе придётся переписываться и встречаться с монстрами, обладающими особыми способностями.",
      ua: "Моторошне побачення, де ви спілкуєтеся та зустрічаєтеся з друзями-монстрами, де в кожного свої особливі здібності.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-four/",
    duration: "15-20 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Civic Doodle",
      ru: "Гражданский холст",
    },
    pack: "The Jackbox Party Pack 4",
    image: {
      en: "civic_doodle.jpg",
      ru: "civic_doodle.png",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://jackboxgames.com/civic-doodle",
    description: {
      en: "The one-up art game where you compete to improve the town murals. Play using your phones, tablets or computers. No extra controllers needed! Plus EVEN MORE features just for streamers!",
      ru: "Все любят красивые рисунки, а лучше красивых рисунков могут быть только красивые рисунки, нарисованные на стенах домов. Их созданием вам и предстоит заняться. Так как желающих много, каждый в свою очередь будет дорисовывать какой-то элемент рисунка. Кто из вас — новый да Винчи, а кому только надписи на заборе писать? Это вам скажет самое строгое жюри из возможных — ваши друзья.",
      ua: "Мистецька гра, в якій ви змагаєтеся за покращення міста малюючи мурали на стінах домів.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-four/",
    duration: "20 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Survive the Internet",
      ru: "Выжить в Интернете",
      ua: "Вижити в Інтернеті",
    },
    pack: "The Jackbox Party Pack 4",
    image: {
      en: "survive_the_internet.jpg",
      ru: "survive_the_internet.png",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://jackboxgames.com/survive-the-internet",
    description: {
      en: "The web-based frame game where you twist your friends’ “online” comments in hilarious ways.",
      ru: "Весёлая игра про Всемирную паутину, где тебе нужно придумать смешной контекст к онлайн-комментариям своих друзей. Отличный повод получить или потерять друзей!",
      ua: "Гра про інтернет, в якій ви перекручуєте коментарі своїх друзів смішним способом.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-four/",
    duration: "20 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Bracketeering",
      ru: "Панччемпионат",
    },
    pack: "The Jackbox Party Pack 4",
    image: {
      en: "bracketeering.jpg",
      ru: "bracketeering.png",
    },
    minPlayers: 3,
    maxPlayers: 16,
    url: "https://jackboxgames.com/bracketeering",
    description: {
      en: "The deranged debate match where you place smart bets on stupid arguments.",
      ru: "Чемпионат из сумасшедших ответов. Делай умные ставки на победу в глупых битвах!",
      ua: "Чемпіонат божевільних дебатів, де ви робите розумні ставки на дурні аргументи.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-four/",
    duration: "15-20 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "You Don't Know Jack - Full Stream",
      ru: "А голову ты не забыл?",
    },
    pack: "The Jackbox Party Pack 5",
    image: {
      en: "full_stream.png",
    },
    minPlayers: 1,
    maxPlayers: 8,
    url: "https://jackboxgames.com/ydkj-full-stream",
    description: {
      en: "The pop-culture trivia mash-up YOU DON’T KNOW JACK: Full Stream. The classic returns, full of wild new surprises.",
      ru: "Классика возвращается! Теперь с участием зрителей. Новые задания и звуки пердежа – уже внутри.",
      ua: "Класика повертається як поп-культурна вікторина, що сповнена нових шалених сюрпризів!",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-five/",
    duration: "15-20 Minutes",
    familyFriendlySetting: false,
    manualCensoring: true,
    extendedTimers: true,
    translations: [],
  },
  {
    name: {
      en: "Split The Room",
      ru: "Раздели комнату",
    },
    pack: "The Jackbox Party Pack 5",
    image: {
      en: "split_the_room.png",
      ru: "split_the_room.png",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://jackboxgames.com/split-the-room",
    description: {
      en: "The what-if game Split the Room. Create strange and divisive hypothetical situations.",
      ru: "Создавай странные и противоречивые ситуации, а затем попытайся поделить всех остальных на два лагеря! Отличный повод потерять половину друзей (если не повезёт).",
      ua: "Гра «Що, якщо», в якій потрібно розділіти кімнату своїми відповідями. Створюйте дивні гіпотетичні ситуації, що викликають розбіжності.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-five/",
    duration: "15 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Mad Verse City",
      ru: "Город злых рифм",
    },
    pack: "The Jackbox Party Pack 5",
    image: {
      en: "mad_verse_city.jpg",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://jackboxgames.com/mad-verse-city",
    description: {
      en: "The lyric-writing game Mad Verse City. Channel your inner MC as a rap battling robot.",
      ru: "Сочини рэп и выиграй баттл в Городе роботов!",
      ua: "Розбудіть свого внтутрішнього МС та вигадайте текст для пісні у цьому реп змаганні роботів!",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-five/",
    duration: "15-20 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: [],
  },
  {
    name: {
      en: "Patently Stupid",
      ru: "Творим патенты",
    },
    pack: "The Jackbox Party Pack 5",
    image: {
      en: "patently_stupid.png",
      ru: "patently_stupid.png",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://jackboxgames.com/patently-stupid",
    description: {
      en: "The competitive drawing game Patently Stupid. Create odd inventions to solve bizarre problems.",
      ru: "Придумай проблему, а затем придумай средство для устранения придуманной проблемы! Представь свой проект, собери инвестиции и выиграй!",
      ua: "Гра на малювання, в якій ви створюєте дивні винаходи для вирішення дивних проблем.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-five/",
    duration: "20 Minutes",
    familyFriendlySetting: false,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Zeeple Dome",
      ru: "Купол Зипол",
    },
    pack: "The Jackbox Party Pack 5",
    image: {
      en: "zeeple_dome.jpg",
    },
    minPlayers: 1,
    maxPlayers: 6,
    url: "https://jackboxgames.com/zeeple-dome",
    description: {
      en: "The deadliest game show in the Crab Nebula, Zeeple Dome! Fling yourself at bloodthirsty aliens to win millions of Zubabucks!",
      ru: "Это самое смертельное шоу во всём паке! Приведи своих друзей и поучавствуй в битве, подготовленной инопланетянами!",
      ua: "Найсмертоносніше ігрове шоу в Крабовидній Туманності! Киньте себе на кровожерливих інопланетян, щоб виграти мільйони Зубабаксів!",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-five/",
    duration: "10+ Minutes",
    familyFriendlySetting: false,
    manualCensoring: false,
    extendedTimers: false,
    translations: [],
  },
  {
    name: {
      en: "Trivia Murder Party 2",
      ru: "Смертельная вечеринка 2",
      ua: "Смертельна вечірка 2",
    },
    pack: "The Jackbox Party Pack 6",
    image: {
      en: "trivia_murder_party_2.jpg",
      ru: "trivia_murder_party_2.png",
    },
    minPlayers: 1,
    maxPlayers: 8,
    url: "https://jackboxgames.com/trivia-murder-party-two/",
    description: {
      en: "The popular trivia deathmatch Trivia Murder Party 2. Try to survive the bizarre new minigames.",
      ru: "Продолжение смертельной викторины. Новая локация, задания и игры на выживание! Как и всегда, останется только один. (Или никого.)",
      ua: "Продовження популярної смертельної вікторини. Спробуйте вижити в нових химерних міні-іграх!",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-six/",
    duration: "15-25 Minutes",
    familyFriendlySetting: false,
    manualCensoring: false,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Role Models",
      ru: "Анализ роли",
    },
    pack: "The Jackbox Party Pack 6",
    image: {
      en: "role_models.jpg",
    },
    minPlayers: 3,
    maxPlayers: 6,
    url: "https://jackboxgames.com/role-models/",
    description: {
      en: "The offbeat personality test Role Models. Find out who you really are. (Or at least what your friends think of you.)",
      ru: "Кто из вас ботаник, любящий бекон, а кто – меланхоличный комбинатор? Всё покажет «Анализ роли»!",
      ua: "Незвичайний тест для аналізу особистості. Дізнайтеся, хто ви є насправді. (Або принаймні, що про вас думають ваші друзі)",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-six/",
    duration: "15 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: [],
  },
  {
    name: {
      en: "Joke Boat",
      ru: "Корабль смеха",
    },
    pack: "The Jackbox Party Pack 6",
    image: {
      en: "joke_boat.jpg",
      ru: "joke_boat.png",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://jackboxgames.com/joke-boat/",
    description: {
      en: "The comedy contest Joke Boat. Craft one-liners for a cruise ship talent show.",
      ru: "Отправляйтесь в смешной до колик круиз, рассмеши всех и получи главный приз — спасение с тонущего корабля!",
      ua: "Комедійний конкурс на круїзному кораблі, в якому вам необхідно створити жарт і росмішити всіх!",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-six/",
    duration: "15-25 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Dictionarium",
      ru: "Словариум",
    },
    pack: "The Jackbox Party Pack 6",
    image: {
      en: "dictionarium.png",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://jackboxgames.com/dictionarium/",
    description: {
      en: "The weird word circus Dictionarium. May the funniest definition win.",
      ru: "Всем словам нужны определения. Не все их имеют. Придумывайте значения слов и создавайте из них уморительные словосочетания!",
      ua: "Цирк із дивних слів. І нехай переможе найсмішніше визначення.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-six/",
    duration: "10 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: [],
  },
  {
    name: {
      en: "Push The Button",
      ru: "Жми на кнопку",
    },
    pack: "The Jackbox Party Pack 6",
    image: {
      en: "push_the_button.jpg",
      ru: "push_the_button.png",
    },
    minPlayers: 4,
    maxPlayers: 10,
    url: "https://jackboxgames.com/push-the-button/",
    description: {
      en: "The hidden identity game Push The Button. Can you discover the aliens in time?",
      ru: "Всё то же «Обмани меня», только теперь в гиперпространстве. Найди жуликов-инопланетян и выгони их с корабля!",
      ua: "Гра прихованої особистості на космічному кораблі. Чи зможете ви вчасно виявити інопланетян?",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-six/",
    duration: "Varies by player count",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: false,
    translations: ["ru"],
  },
  {
    name: {
      en: "Quiplash 3",
      ru: "Смехлыст 3",
    },
    pack: "The Jackbox Party Pack 7",
    image: {
      en: "quiplash_3.jpg",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://www.jackboxgames.com/quiplash-three/",
    description: {
      en: "The say-anything threequel Quiplash 3 (3-8 players). It’s a head-to-head battle of the wits as you give hilarious responses to quirky prompts while everyone else votes for their favorite!",
      ru: "Триквел знаменитой игры – та же старая-добрая битва шуток, но теперь в пластилиновом мире и с новым финалом!",
      ua: "Триквел відомої гри - та сама спільна битва розумів, де ви даєте веселі відповіді і всі інші голосують за свого фаворита!",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-seven/",
    duration: "~15 minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "The Devils and the Details",
      ru: "Дьяволы в деталях",
    },
    pack: "The Jackbox Party Pack 7",
    image: {
      en: "the_devils_and_the_details.jpg",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://www.jackboxgames.com/the-devils-and-the-details/",
    description: {
      en: "The collaborative chaos game The Devils and the Details (3-8 players). You’re a family of devils working together to survive in suburbia. Can you handle the daily torture of human life?",
      ru: "Вы – семья дьяволов, перебравшаяся из ада на землю. Работая в команде, выполняйте обыденные дела людей и постарайтесь выжить в круговороте забот!",
      ua: "Ви — сім’я дияволів, які разом працюють, щоб вижити на землі. Чи зможете ви впоратися з щоденними тортурами людського життя?",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-seven/",
    duration: "~15 minutes",
    familyFriendlySetting: true,
    manualCensoring: false,
    extendedTimers: false,
    translations: ["ru"],
  },
  {
    name: {
      en: "Champ’d Up",
      ru: "ГладиАРТоры",
    },
    pack: "The Jackbox Party Pack 7",
    image: {
      en: "champd_up.jpg",
      ru: "champd_up.png",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://www.jackboxgames.com/champd-up/",
    description: {
      en: "The drawing fighting game Champ’d Up (3-8 players). Create absurd characters that will battle over unusual titles. Can you take down the heavy favorite?",
      ru: "Нарисуй бойцов, которые смогут стать чемпионами в борьбе за самые странные титулы!",
      ua: "Намалюйте абсурдних персонажів, які будуть битися за дуже незвичайні звання.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-seven/",
    duration: "~20 minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Talking Points",
      ru: "На пальцах",
    },
    pack: "The Jackbox Party Pack 7",
    image: {
      en: "talking_points.png",
      ru: "talking_points.png",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://www.jackboxgames.com/talking-points/",
    description: {
      en: "The on-the-spot public-speaking game Talking Points (3-8 players). Give a speech responding to picture slides you’ve never seen before or be the Assistant and approve pictures as fast as you can. Just keep talking whether it makes sense or not.",
      ru: "Выступи с презентацией, которую увидишь в первый раз. Просто без остановки говори о выбранных другим игроком слайдах, и неважно, будет ли в твоих словах смысл!",
      ua: "Виступіть із презентацією, відповідаючи на слайди зображень, які ви ніколи раніше не бачили, або будьте помічником і обирайте зображення якомога швидше. Просто продовжуйте говорити, має це сенс чи ні.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-seven/",
    duration: "20-40 minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Blather ‘Round",
      ru: "Густой трёп",
    },
    pack: "The Jackbox Party Pack 7",
    image: {
      en: "blather_round.jpg",
    },
    minPlayers: 2,
    maxPlayers: 6,
    url: "https://www.jackboxgames.com/blather-round/",
    description: {
      en: "The pop culture guessing game Blather Round (2-6 players). Describe your secret prompt with a very limited vocabulary and hope that someone can figure it out in time. It’s a “GOOD” “FUN TIME” “EXPERIENCE.”",
      ru: "Игра на угадывание объектов поп-культуры. Опиши загаданное, используя очень ограниченный набор слов!",
      ua: "Гра, в якій потрібно вгадувати обʼєкти із поп-культури. Опишіть свою таємну підказку з дуже обмеженим словниковим запасом і сподівайтеся, що хтось зможе це зрозуміти.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-seven/",
    duration: "15-30 minutes",
    familyFriendlySetting: true,
    manualCensoring: false,
    extendedTimers: false,
    translations: [],
  },
  {
    name: {
      en: "Drawful Animate",
      ru: "Рисовач: Анимач",
    },
    pack: "The Jackbox Party Pack 8",
    image: {
      en: "drawful_animate.jpg",
    },
    minPlayers: 3,
    maxPlayers: 10,
    url: "https://www.jackboxgames.com/drawful-animate/",
    description: {
      en: "It’s alive! The guessing game with terrible drawings and hilariously wrong answers makes a dynamic return. In this revamped title, players create looping, two-frame animations based on weird and random titles.",
      ru: "Триквел старой-доброй игры на рисование. Но теперь игроки рисуют не эти скучные рисунки, а настоящие анимации!",
      ua: "Триквел старої гри на вгадування із жахливими малюнками і веселими неправильними відповідями. У цій новій частині гравці створюють циклічні двокадрові анімації на основі дивних і випадкових назв.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-eight/",
    duration: "15-30 minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "Job Job",
      ru: "За работой",
    },
    pack: "The Jackbox Party Pack 8",
    image: {
      en: "job_job.png",
    },
    minPlayers: 3,
    maxPlayers: 10,
    url: "https://www.jackboxgames.com/job-job/",
    description: {
      en: "Use other people’s words to create unique and funny answers to classic job interview questions. Go head to head to see who scores the job!",
      ru: "Вырывай любые слова из предложений других игроков, чтобы ответить на вопросы собеседования и получить работу!",
      ua: "Використовуйте слова інших людей, щоб створити унікальні та кумедні відповіді на класичні питання співбесіди та отримати роботу!",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-eight/",
    duration: "15-25 minutes",
    familyFriendlySetting: true,
    manualCensoring: false,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "The Poll Mine",
      ru: "Шахты Мнений",
    },
    pack: "The Jackbox Party Pack 8",
    image: {
      en: "the_poll_mine.jpg",
    },
    minPlayers: 2,
    maxPlayers: 10,
    url: "https://www.jackboxgames.com/the-poll-mine/",
    description: {
      en: "A survey game that’s all about YOU! Split into teams and see who can escape from the witch’s lair! Players individually rank their choices to a difficult question, then must guess how the group answered as a whole. How well do you know your friends?!",
      ru: "Вместе со своей командой находи двери с правильными ответами на опросы, чтобы выбраться из страшной пещеры!",
      ua: "Командна гра-опитування, у якій ви вгадуєте правильні відповіді, щоб втекти з лігва відьми!",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-eight/",
    duration: " ~15 minutes minutes",
    familyFriendlySetting: true,
    manualCensoring: false,
    extendedTimers: false,
    translations: [],
  },
  {
    name: {
      en: "Weapons Drawn",
      ru: "Преступление и рисование",
    },
    pack: "The Jackbox Party Pack 8",
    image: {
      en: "weapons_drawn.jpg",
    },
    minPlayers: 4,
    maxPlayers: 8,
    url: "https://www.jackboxgames.com/weapons-drawn/",
    description: {
      en: "A social deduction game where everyone is both a murderer and a detective. Players doodle all the clues, hiding a letter from their name in the weapon drawings. Can you solve murders while trying to get away with your own?",
      ru: "Игра в жанре «мафии», где каждый – и детектив, и убийца.",
      ua: "Гра соціальної дедукції, де кожен є і вбивцею, і детективом. Гравці малюють всі підказки, приховуючи літеру свого імені на малюнках зброї. Та чи зможете ви розкрити ці вбивства?",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-eight/",
    duration: "20-40 minutes",
    familyFriendlySetting: false,
    manualCensoring: false,
    extendedTimers: true,
    translations: ["ru"],
  },
  {
    name: {
      en: "The Wheel of Enormous Proportions",
      ru: "Колесо невероятных пропорций",
    },
    pack: "The Jackbox Party Pack 8",
    image: {
      en: "the_wheel_of_enormous_proportions.jpg",
      ru: "the_wheel_of_enormous_proportions.png",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://www.jackboxgames.com/the-wheel-of-enormous-proportions/",
    description: {
      en: "Trivia has never been so large! A fantastic, mystical wheel challenges you with a variety of trivia prompts. Winners are awarded slices of the Wheel’s face with a chance to win big with each nail-biting spin. In the end, one player will have their most burning question answered by the great Wheel.",
      ru: "Отвечай на вопросы правильно, чтобы увеличить свои шансы на победу. А выиграв, ты сможешь получить ответ на любой свой вопрос.",
      ua: "Відповідай на запитання правильно, щоб збільшити свої шанси на перемогу. А вигравши, ти зможеш отримати відповідь на своє питання.",
    },
    packUrl: "https://www.jackboxgames.com/party-pack-eight/",
    duration: "15-25 minutes",
    familyFriendlySetting: true,
    manualCensoring: false,
    extendedTimers: false,
    translations: [],
  },
  {
    name: {
      en: "Drawful 2",
      ru: "Рисовач 2",
    },
    pack: "Standalone Title",
    image: {
      en: "drawful_2.jpg",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://jackboxgames.com/drawful-two/",
    description: {
      en: "Your phones or tablets are your controllers! The game of terrible drawings and hilariously wrong answers.",
      ru: "Вторая часть игры-рисовашки. Рисуй понятные рисунки, а твои друзья постараются отгадать их. Но они тоже в деле и будут предлагать свои варианты, дабы сбить всех с толку! Кто из вас рисует красивее всех, а кто понятнее всех?",
      ua: "Дру частина гри жахливих малюнків і веселих неправильних відповідей.",
    },
    duration: "15-20 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: [],
  },
  {
    name: {
      en: "Fibbage",
      ru: "Бредовуха",
    },
    pack: "Standalone Title",
    minPlayers: 2,
    maxPlayers: 8,
    url: "https://www.jackboxgames.com/fibbage/",
    description: {
      en: "Fibbage is the lying, bluffing, fib-till-you-win trivia party game from the makers of YOU DON’T KNOW JACK! Fool your friends with your lies, avoid theirs, and find the (usually outrageous) truth. And get this: your phone or tablet is your controller!",
      ua: "Гра - це суцільна брехня, у якій вам потрібно обдуріти своїх друзів своєю брехнею, та знайти (зазвичай обурливу) правду.",
    },
    duration: "15-20 Minutes",
    familyFriendlySetting: false,
    manualCensoring: false,
    extendedTimers: false,
    translations: [],
  },
  {
    name: {
      en: "Quiplash",
      ru: "Смехлыст",
    },
    pack: "Standalone Title",
    image: {
      en: "quiplash.jpg",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://www.jackboxgames.com/quiplash/",
    description: {
      en: "Quiplash is the gut-busting battle of wits and wittiness!",
      ua: "Боротьба розуму та дотепності, що розмішить усіх!",
    },
    duration: "15 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: [],
  },
  {
    name: {
      en: "You Don't Know Jack - Classic Bundle",
      ru: "А голову ты не забыл? - Классическая версия",
    },
    pack: "Standalone Title",
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://www.jackboxgames.com/ydkj-classic-bundle/",
    description: {
      en: "Since 1995, Jackbox Games (formerly Jellyvision) has been making YOU DON’T KNOW JACK, the hit comedy trivia series where high culture and pop culture collide. And we refuse to stop until every human being has played it.",
      ua: "Хітова комедійна вікторина, де стикаються висока та поп культура.",
    },
    duration: "25 Minutes",
    familyFriendlySetting: false,
    manualCensoring: false,
    extendedTimers: false,
    translations: [],
  },
  {
    name: {
      en: "Quiplash 2 InterLASHional",
      ru: "Смехлыст 2 Международный",
    },
    pack: "Standalone Title",
    image: {
      en: "quiplash_2_interlashional.jpg",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://www.jackboxgames.com/quiplash-two-interlashional/",
    description: {
      en: "The “say anything” party game is going international! All the hilarious content from Quiplash 2, now in French, Italian, German, and Spanish! With 100 NEW prompts in each language! (And you can still play in boring old English.) Get this QUINTILINGUAL Quiplash now, and take your next party global!",
      ua: "Та сама гра Quiplash, але вже міжнародна! Весь веселий контент із Quiplash 2 тепер французькою, італійською, німецькою та іспанською мовами! Але тепер із 100 НОВИМИ запитаннями!",
    },
    duration: "15 Minutes",
    familyFriendlySetting: true,
    manualCensoring: true,
    extendedTimers: true,
    translations: [],
  },
  {
    name: {
      en: "Roomerang",
    },
    pack: "The Jackbox Party Pack 9",
    packUrl: "https://www.jackboxgames.com/party-pack-nine/",
    image: {
      en: "roomerang.jpg",
    },
    minPlayers: 4,
    maxPlayers: 9,
    url: "https://www.jackboxgames.com/roomerang/",
    description: {
      en: "In Roomerang, channel your inner reality TV star in an attempt to come out on top! Respond to prompts, bring the competition and role-play to avoid being voted out.",
    },
    duration: "25-35 minutes",
    familyFriendlySetting: true,
    manualCensoring: null,
    extendedTimers: null,
    translations: [],
  },
  {
    name: {
      en: "Junktopia",
    },
    pack: "The Jackbox Party Pack 9",
    packUrl: "https://www.jackboxgames.com/party-pack-nine/",
    image: {
      en: "junktopia.jpg",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://www.jackboxgames.com/junktopia/",
    description: {
      en: "In Junktopia, a strange wizard has turned you into a frog! Create hilarious backstories for weird objects and then get them appraised. The player with the most valuable items becomes human again!",
    },
    duration: "15-25 minutes",
    familyFriendlySetting: true,
    manualCensoring: null,
    extendedTimers: null,
    translations: [],
  },
  {
    name: {
      en: "Nonsensory",
    },
    pack: "The Jackbox Party Pack 9",
    packUrl: "https://www.jackboxgames.com/party-pack-nine/",
    image: {
      en: "nonsensory.jpg",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://www.jackboxgames.com/nonsensory/",
    description: {
      en: "Professor Nanners is here to test your NSP (Nonsensory Perception) in this drawing, writing, and guessing game! How close can you get to guessing where another player’s prompt ranks on the silliest of scales?",
    },
    duration: "15-25 minutes",
    familyFriendlySetting: true,
    manualCensoring: null,
    extendedTimers: null,
    translations: [],
  },
  {
    name: {
      en: "Fibbage 4",
    },
    pack: "The Jackbox Party Pack 9",
    packUrl: "https://www.jackboxgames.com/party-pack-nine/",
    image: {
      en: "fibbage_4.jpg",
    },
    minPlayers: 3,
    maxPlayers: 8,
    url: "https://www.jackboxgames.com/fibbage-four/",
    description: {
      en: "The hilarious bluffing party game returns with an all-new Final Fibbage, video questions, fan-submitted questions, and Fibbage Enough About You mode! It’s a game so beloved that we decided to slap a 4 on it.",
    },
    duration: "20 minutes",
    familyFriendlySetting: true,
    manualCensoring: null,
    extendedTimers: null,
    translations: [],
  },
  {
    name: {
      en: "Quixort",
    },
    pack: "The Jackbox Party Pack 9",
    packUrl: "https://www.jackboxgames.com/party-pack-nine/",
    image: {
      en: "quixort.jpg",
    },
    minPlayers: 1,
    maxPlayers: 10,
    url: "https://www.jackboxgames.com/quixort/",
    description: {
      en: "In this trivia sorting factory, work with your team to sort falling answers into their proper order before they hit the floor! Or, play the single player mode and see how many blocks you can sort before topping out. It’s as easy as A, C, B!",
    },
    duration: "15-20 minutes",
    familyFriendlySetting: true,
    manualCensoring: null,
    extendedTimers: null,
    translations: [],
  },
];

export default games;
